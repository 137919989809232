import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []


  checklocationSuitability() {
    const maxServiceRegionKM = 180 // This is half the renage of a Model S

    let carTypeID = $('.car-selection-button.selected').data('type-id')
    let pickupLocatinDetailsElement = $(event.target).closest('[data-trip-details]').find('[data-pickup-location-details]')

    let lat = pickupLocatinDetailsElement.find('[data-latitude]').val()
    let lng = pickupLocatinDetailsElement.find('[data-longitude]').val()
    let googlePlace = new google.maps.LatLng(lat, lng)

    let seriveRegion = $('[data-service-regions]').data('serviceRegions').find(function(sr) {
      let regionCenterLatLng = new google.maps.LatLng(sr.geo_center_lat, sr.geo_center_lng)
      return greatCircleDistanceKm(regionCenterLatLng, googlePlace) < maxServiceRegionKM
    })

    let context = this

    $.ajax({
      type: 'GET',
      url: '/service_regions/' + seriveRegion.id + '/car_types/' + carTypeID,
      async: false,
      beforeSend: $.rails.CSRFProtection,
      dataType: 'json',
      cache: false,
      success(data) {
        return true
      },
      error(request, textStatus, errorThrown) {
        context.showCarTypeNotificationBox(JSON.parse(request.responseText).error)
      }
    })
  }

  private
    showCarTypeNotificationBox(errorMsg) {
      let notificationInstructionText = "<p>We can assist you with booking in the transfers you require - simply click “Contact Us” and we will get back to you shortly. If you’d like to book in the transfers in [Alternate Service Region] only remove the new transfer you’ve entered.</p>"
      let notificationActions = "<div class='notification__message_actions'><a class='button button-fill-violet button-sm' data-action=''>Contact Us</a> <a class='primary-link' data-close-notification data-action=''>Remove Transfer in [Service Region]</a> </div>"

      let notificationBox = $('<div class="notification_box" data-car-type-notification-box><div class="notification__icon"></div><div class="notification__message">' + errorMsg + notificationInstructionText + notificationActions +'</div></div>')
      notificationBox.insertAfter($('.car-selection-button.selected'))
      notificationBox.show()
    }
}
