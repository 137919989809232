import { ApplicationController } from "./application_controller.js"

export default class extends ApplicationController {
  static targets = ['test']

  checkDetails() {
    let currentElement = $(event.target)
    let context = this
    let tripDetailsContainer = currentElement.closest('[data-trip-details]')
    let flightNumElement = $(tripDetailsContainer).find('input[data-cy-flight-number]')
    if ($(flightNumElement).val() != null && $(flightNumElement).val().length){
      let suspendFlightValidation = ($(tripDetailsContainer).find('[data-ignore-arrival-pickup-time-variation]').val() == 'true')

      if(!suspendFlightValidation){
        $(flightNumElement).siblings('[data-ignore-arrival-pickup-time-variation]').val(false)
      }

      $(flightNumElement).siblings('.flight-number-spinner').show()

      let flightData = context.flightLookup($(flightNumElement).last())

      if(flightData.length == 0) { return false }

      let validFlightDetails = false

      if (flightData.error !== undefined) {
        validFlightDetails = true
        // Display notification box for error
        context.showFlightNotificationBox($(flightNumElement), flightData.error)
      }
      else {

        const tripIndex = flightNumElement[0].dataset.tripIndex;
        const tripPickupAtElement = $(`[data-trip-index="${tripIndex}"][data-tag="pickup-date-time-${tripIndex}"]`);
        let defaultDate = tripPickupAtElement[0].value;

        context.showFlightInfo($(flightNumElement), flightData)
        // let pickupDate = $('input.date_selector').val(); // Assuming class for date input
        // let pickupHour = $('input.slick_time_picker__hour').val(); // Assuming class for hour input
        // let pickupMinute = $('input.slick_time_picker__minute').val(); // Assuming class for minute input
        // let meridiem = $('input[type="radio"][name="dfa7_meridiem"]:checked').val(); // More specific selector

        // // Adjust hour based on AM/PM
        // if (meridiem === 'PM' && pickupHour !== '12') {
        //     pickupHour = parseInt(pickupHour, 10) + 12;
        // } else if (meridiem === 'AM' && pickupHour === '12') {
        //     pickupHour = 0;
        // }



        // let dateTimeString = pickupDate + ' ' + pickupHour + ':' + pickupMinute + ':00';
        // let departureTime = new Date(dateTimeString);
        let departureTime = new Date(defaultDate);

        let pickupLocationDetails = $(tripDetailsContainer).find('[data-pickup-location-details]')
        let dropoffLocationDetails = $(tripDetailsContainer).find('[data-dropoff-location-details]')

        let pickupLat = $(pickupLocationDetails).find('[data-latitude]').val()
        let pickupLng = $(pickupLocationDetails).find('[data-longitude]').val()

        let dropoffLat = $(dropoffLocationDetails).find('[data-latitude]').val()
        let dropoffLng = $(dropoffLocationDetails).find('[data-longitude]').val()

        let pickupCoords = new google.maps.LatLng(pickupLat, pickupLng)
        let dropoffCoords = new google.maps.LatLng(dropoffLat, dropoffLng)


        //Find the distance
        let distanceService = new (google.maps.DistanceMatrixService);

         distanceService.getDistanceMatrix({
           origins: [ pickupCoords ],
           destinations: [ dropoffCoords ],
           travelMode: google.maps.TravelMode.DRIVING,
           unitSystem: google.maps.UnitSystem.METRIC,
           drivingOptions: {
             departureTime: departureTime,
             trafficModel: 'pessimistic'
           }

         },
         function(response, status) {
           if (status !== google.maps.DistanceMatrixStatus.OK) {
              console.log('Error:', status);
           } else {
            const duration = response.rows[0].elements[0].duration.value;
            const durationInTraffic = response.rows[0].elements[0].duration_in_traffic.value;

            const maxDuration = context.getMaxDuration(duration, durationInTraffic);

            if (maxDuration) {
              $(tripDetailsContainer).find('[data-trip-duration]').val(maxDuration);
              context.checkFlightSuitability($(flightNumElement), flightData);
            }
          }
        })
      }
    }

    const customEvent = new CustomEvent('airportAdded', { bubbles: true });
    document.dispatchEvent(customEvent);
  }

  resetFlight() {
    let flightNumberElement = $(event.target)

    if(flightNumberElement.data('valid-flight') == true) {
      $(flightNumberElement).data('valid-flight', false)
      $(flightNumberElement).attr('data-valid-flight', false)

      const customEvent = new CustomEvent('airportAdded', { bubbles: true });
      document.dispatchEvent(customEvent);
    }
  }

  private

    getMaxDuration(duration, durationInTraffic) {
      if (duration == null && durationInTraffic == null) {
        return null;
      }
      if (duration == null) {
        return durationInTraffic;
      }
      if (durationInTraffic == null) {
        return duration;
      }
      return Math.max(duration, durationInTraffic);
    }

    showFlightNotificationBox(flightNumberElement, errorMsg) {
      let flightInfo = $(flightNumberElement).closest('.flight-info')
      let notificationBox = $(flightInfo).find('.notification_box')


      $(notificationBox).find('.notification__message').html(errorMsg)
      $(notificationBox).fadeIn('slow', function() {
        if(!$(flightInfo).hasClass('flight_stat-double_spacing')) {
          $(flightInfo).addClass('flight_stat-double_spacing')
        }
        $(this).show()
        $(flightInfo).find('.flight-number-spinner').hide()
      })

      $(notificationBox).find('[data-close-notification]').click(function(e) {
        $(notificationBox).fadeOut('slow', function() {
          $(this).hide()
          $(flightNumberElement).removeClass('error')
          $(flightNumberElement).siblings('[data-ignore-arrival-pickup-time-variation]').val(true)
        })
      })
    }

    showFlightInfo(flightNumberElement, flightData) {
      const flightInfo = $(flightNumberElement).closest('.flight-info')
      const notificationBox = $(flightInfo).find('.notification_box')

      $(flightInfo).addClass('flight_stat-double_spacing')

      $(flightInfo).find('[data-flight-schedule-departure-time]').text(flightData.airport_departure_time)
      $(flightInfo).find('[data-flight-schedule-arrival-time]').text(flightData.airport_arrival_time)
      $(flightInfo).find('[data-flight-schedule-departure-iata]').text(flightData.departure_iata_code)
      $(flightInfo).find('[data-flight-schedule-arrival-iata]').text(flightData.arrival_iata_code)
      $(flightInfo).find('[data-flight-number]').text(flightData.flight_number)
      $(flightInfo).find('[data-flight-schedule-flight-time]').text(flightData.flight_time)
      $(flightInfo).find('[data-flight]').val(flightData.id)

      $(flightInfo).find('[data-carrier-logo]').attr('src', `https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/${flightData.carrier_code}.svg`);

      $(flightInfo).find('.flight-number-spinner').hide()

      $(flightInfo).find('[data-flight-stats]').animate({ display: 'inline-block' }, 1000, function() {
        $(this).removeClass('hidden')
      })

      $(flightNumberElement).parent().fadeOut('slow', function() {
        $(this).hide()
        $(flightInfo).find('.flight-number-spinner').hide()
        $(flightInfo).find('[data-flight-stats]').fadeIn('slow', function() {
          $(this).removeClass('hidden')
        })
      })

      $('[data-flight-number-edit-action]').click(function(e) {
        $(this).closest('[data-flight-stats]').fadeOut('slow', function() {
          $(flightInfo).removeClass('flight_stat-double_spacing')
          $(this).hide()
          $(flightInfo).find('.flight-number-spinner').hide()
          $(notificationBox).hide()
          $(flightNumberElement).siblings('[data-ignore-arrival-pickup-time-variation]').val(false)

          $(flightNumberElement).parent().fadeIn('slow', function() {
            $(this).show()
          })
        })
      })
    }

    checkFlightSuitability(flightNumberElement, flightData) {
      let flightDirection, flightTime
      let airportElement = $(flightNumberElement).closest('[data-trip-details]').find('[data-airport]')
      let errorMsg = null
      let pickupTime = $(airportElement).closest('[data-trip-details]').find('[data-cy-pickup-at]').val()
      let notificationBox = $(flightNumberElement).closest('[data-trip-details]').find('.notification_box')

      $(flightNumberElement).data('valid-flight', false)
      $(flightNumberElement).attr('data-valid-flight', true)

      $(notificationBox).fadeOut('slow', function() {
        $(this).hide()
        $(flightNumberElement).removeClass('error')
      })

      if ($(airportElement).data('type') === 'pick_up_location') {
        flightDirection = 'arriving'

        if (flightData.arrival_iata_code !== $(airportElement).data('airport').iata_code) {
          let arrival_str = flightData.arrival_city.length ? flightData.arrival_city : flightData.arrival_iata_code
          errorMsg = `The flight number you have provided arrives in ${arrival_str}. Please check the flight details.`
        } else {
          flightTime = flightData.arrival_date.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/i)[0]

          let ignoreCheck = ($(flightNumberElement).siblings('[data-ignore-arrival-pickup-time-variation]').val() == 'true')

          let momentPickupTime = moment(pickupTime.split(/[+-]\d{2}:\d{2}$/)[0].trim())
          let minutes = momentPickupTime.minutes();
          let roundedMinutes = Math.ceil(minutes / 5) * 5;
          momentPickupTime.minutes(roundedMinutes).seconds(0);

          if ( (!ignoreCheck) && ((moment(flightTime).toDate() < moment(momentPickupTime).toDate()) || (moment(flightTime).toDate() > moment(momentPickupTime).toDate())) ) {
            let recommendedPickup = moment(flightData.arrival_date.split(/[+-]\d{2}:\d{2}$/)[0]).utc().format('YYYY-MM-DDTHH:mm:SS')  // NOTE: No timezone offset, as only want the base time portion (recommendedDate is a misnomer)
            errorMsg = `<p>The flight number you have provided arrives at ${$.trim(flightData.airport_arrival_time)}, which does not match your pick up time. Would you like to set your pick up time to the arrival time of the flight?</p>`
            errorMsg += `<div class='notification__message_actions'><a class='button button-fill-violet button-sm' data-action='booking#changePickupTime' data-cy-change-pickup-time data-recommended-pickup-at='${recommendedPickup}'>YES, PLEASE</a> <a class='primary-link' data-close-notification data-action='booking#keepPickupTime'>No, thanks</a> </div>`
          }
        }
      } else {
        flightDirection = 'departing'

        if (flightData.departure_iata_code !== $(airportElement).data('airport').iata_code) {
          let departure_str = flightData.departure_city.length ? flightData.departure_city : flightData.departure_iata_code
          errorMsg = `The flight number you have provided departs from ${departure_str}. Please check the flight details.`
        } else {
          flightTime = flightData.departure_date.match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/i)[0]

          // Ideally, we would have ENV vars or some kind of central configuration for this, but for now, we will hardcode the values
          const minutesRequiredForCheckinInternational = 90
          const minutesRequiredForCheckinDomestic = 20

          let isInternationalFlight = (flightData.arrival_country !== flightData.departure_country)
          let minutesRequiredForCheckin = isInternationalFlight ?  minutesRequiredForCheckinInternational : minutesRequiredForCheckinDomestic

          let tripContainer = $(flightNumberElement).closest('[data-trip-details]')

          let estimatedDurationSeconds = $(tripContainer).find('[data-trip-duration]').val()

          let travelDurationMinutes = ((estimatedDurationSeconds / 60) + (minutesRequiredForCheckin))

          let minimumPickupTime = moment(flightTime).subtract(travelDurationMinutes, 'minutes')
          let minutes = minimumPickupTime.minutes();
          let roundedMinutes = Math.floor(minutes / 5) * 5;
          minimumPickupTime.minutes(roundedMinutes).seconds(0);

          if (minimumPickupTime < moment(pickupTime.split(/[+-]\d{2}:\d{2}$/)[0].trim())) {
            let recommendedDate = minimumPickupTime.format('YYYY-MM-DDTHH:mm:SS') // NOTE: No timezone offset, as only want the base time portion (recommendedDate is a misnomer)
            errorMsg = `<p>The flight number you have provided departs at ${$.trim(flightData.airport_departure_time)} and the pick up time you have provided does not provide enough time to arrive for the flight. Please check the pick up time or flight details. (We recommend a pick up time of ${moment(minimumPickupTime).format('LT')}).</p>`
            errorMsg += `<div class='notification__message_actions'><a class='button button-fill-violet button-sm' data-action='booking#changePickupTime' data-cy-change-pickup-time data-recommended-pickup-at='${recommendedDate}'>YES, PLEASE</a> </div>`
          }

          // Commented out as we will be coming back to this at a later stage
          // this.showAddRegionalAirportPickupTripButton(airportElement, flightData)

        }
      }

      if (errorMsg !== null) {
        this.showFlightNotificationBox(flightNumberElement, errorMsg)
      }
      else {
        $(flightNumberElement).data('valid-flight', true)
        $(flightNumberElement).attr('datavalid-flight', true)

        // Create event and trigger fare calculation
        let event = new CustomEvent('validFlightDetected', { detail: { message: "Valid flight detected" } });
        document.dispatchEvent(event);

        let notificationBox = $(flightNumberElement).closest('[data-trip-details]').find('.notification_box')
        $(notificationBox).fadeOut('slow', function() {
          $(this).hide()
          $(flightNumberElement).siblings('[data-ignore-arrival-pickup-time-variation]').val(false)
          $(flightNumberElement).removeClass('error')
        })
      }

      const customEvent = new CustomEvent('airportAdded', { bubbles: true });
      document.dispatchEvent(customEvent);

      let retVal = (errorMsg === null)

      return retVal
    }

    // This does not appear to be used anywhere, so the check_city_availability endpoint is also probably not used
    showAddRegionalAirportPickupTripButton(airportElement, flightData) {
      const arrivalCity = flightData.arrival_city;

      $.ajax({
        url: '/check_city_availability',
        type: 'POST',
        data: { city: arrivalCity },
        success: function(response) {
          if (response.available) {
            airportElement.closest('.form-group.row')
              .find('.button-regional_pickup')
              .text('+ Trip in ' + arrivalCity);

            airportElement.closest('.form-group.row')
              .find('.button-regional_pickup')
              .attr('data-flight-data', JSON.stringify(flightData));
            airportElement.closest('.form-group.row')
              .find('.button-regional_pickup')
              .show();
          }
        },
        error: function(xhr, status, error) {
          console.error("Error checking city availability:", error);
        }
      });
    }
}
