import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["step", "steplabel", "nextButton", "pickupLocation", "dropoffLocation", "terms", "firstName", "contactFirstName", "lastName", "contactLastName", "phoneNumber", "contactPhoneNumber", "emailAddress", "contactEmailAddress", "paymentSpinner", "passengerSection", "cardNumber", "savedCardDetails", "newPaymentDetails", "cardElement", "paymentSection", "passengerCheckbox", "customerStripeId", "renewCard"];
  currentStep = 1;

  connect() {
    const url = window.location.href;
    const button = document.getElementById('proceedButton');
    if (!url.includes('book_now')) {
      button.disabled = true;
    } else {
      button.disabled = false;
    }
    document.addEventListener('carSelected', this.validateCars.bind(this));
    document.addEventListener('new-trip-added', this.disableButton.bind(this));
    document.addEventListener('return-trip-added', this.disableButton.bind(this));
    document.addEventListener('trip-removed', this.validateFields.bind(this));
    document.addEventListener('seatRemoved', this.validateFields.bind(this));
    document.addEventListener('stopRemoved', this.validateFields.bind(this));
    document.addEventListener('handleCardDetails', this.validatePaymentDetails.bind(this));
    document.addEventListener('nextButton', this.proceed.bind(this));
    document.addEventListener('backButton', this.previous.bind(this));
    document.addEventListener('airportAdded', this.validateFields.bind(this));

    this.showCurrentStep();
    this.validateFields();
  }

   disconnect() {
    document.removeEventListener('carSelected', this.validateCars.bind(this));
    document.removeEventListener('new-trip-added', this.disableButton.bind(this));
    document.removeEventListener('return-trip-added', this.disableButton.bind(this));
    document.removeEventListener('trip-removed', this.validateFields.bind(this));
    document.removeEventListener('stopRemoved', this.validateFields.bind(this));
    document.removeEventListener('handleCardDetails', this.validatePaymentDetails.bind(this));
    document.removeEventListener('nextButton', this.proceed.bind(this));
    document.removeEventListener('backButton', this.previous.bind(this));
  }

  proceed(event) {
    this.nextButtonTarget.next();
  }

  enableButton(event) {
    this.nextButtonTarget.disabled = false;
    this._dispatchEnableButtonEvent(false);
  }

  disableButton(event) {
    this.nextButtonTarget.disabled = true;
    this._dispatchEnableButtonEvent(true);
  }

  handleLocationSelection(event) {
    event.target.dataset.validLocation = 'true';
    $('.date_selector, .slick_time_picker__hour, .slick_time_picker__minute, .slick_time_picker__meridiem_selector').each(function() {
      $(this).valid();
    });
    this.validateFields();
  }

  blockManualLocation(event) {
    event.target.dataset.validLocation = 'false';
    this.validateFields();
  }

  validateCars() {
    const vehicleTypeElements = document.querySelectorAll('[data-target="booking-summary.vehicleType"]');

    const visibleVehicleTypeElements = Array.from(vehicleTypeElements).filter(el => {
        const parentColMd12 = el.closest('.col-md-12'); // Find the closest parent with the class "col-md-12"
        // Check if the parent exists and does not have the class "hidden"
        return parentColMd12 && !parentColMd12.classList.contains('hidden');
    });

    this.nextButtonTarget.disabled = !(visibleVehicleTypeElements.length === vehicleTypeElements.length);
    this._dispatchEnableButtonEvent(this.nextButtonTarget.disabled);
  }

  addChildSeat() {
    this.nextButtonTarget.disabled = true
  }


  validateFields() {
    const pickupLocationTargets = document.querySelectorAll('[data-target="stepper.pickupLocation"]');
    const dropoffLocationTargets = document.querySelectorAll('[data-target="stepper.dropoffLocation"]');
    const dateInputs = document.querySelectorAll('.fp_datepicker');
    const minuteInputs = document.querySelectorAll('.flatpickr-minute')
    const hourInputs = document.querySelectorAll('.flatpickr-hour')
    const flightNumberInputs = document.querySelectorAll('input[data-flight-number]')

    const hasAirport = document.querySelectorAll('[data-airport]').length > 0;

    const allPickupsValid = Array.from(pickupLocationTargets).every(pickup => pickup.dataset.validLocation === 'true');
    const allDropoffsValid = Array.from(dropoffLocationTargets).every(dropoff => dropoff.dataset.validLocation === 'true');
    const allFlightNumbersValid = hasAirport ? Array.from(flightNumberInputs).every(flightNumber => flightNumber.dataset.validFlight === 'true') : 'true';

    const allDatesValid = Array.from(dateInputs).every(input => {
      return input.value.trim() !== '';
    });

    const allMinuteValid = Array.from(minuteInputs).every(input => {
      return input.value.trim() !== '';
    });

    const allHourValid = Array.from(hourInputs).every(input => {
      return input.value.trim() !== '';
    });

    const divsWithErrors = document.querySelectorAll('div .error');
    const containsError = divsWithErrors.length > 0;

    this.nextButtonTarget.disabled = !(allPickupsValid && allDropoffsValid && allFlightNumbersValid && allDatesValid && allMinuteValid && allHourValid && !containsError);
    this._dispatchEnableButtonEvent(this.nextButtonTarget.disabled);
  }

  validateContactDetails(event) {
    if (event) {
      const el = $(event.target);
      el.valid();
      // el.removeClass('error');
      // el.parent().find('label.error').parent().remove();
    }

    setTimeout(() => {
      let allFieldsFilled = true;
      let emailIsValid = true;
      let requiredFields = Array.from(document.querySelectorAll('.required-contact-field'));

      if (this.passengerCheckboxTarget.checked) {
        const passengerFields = Array.from(document.querySelectorAll('.required-passenger-field'));
        requiredFields = requiredFields.concat(passengerFields);
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      requiredFields.forEach(field => {
        if (!field.value.trim()) {
          allFieldsFilled = false;
        }

        if (field.type === 'email' && !emailRegex.test(field.value.trim())) {
          emailIsValid = false;
        }
      });

      const divsWithErrors = document.querySelectorAll('.error');
      const containsError = divsWithErrors.length > 0;

      if (allFieldsFilled && emailIsValid && !containsError) {
        this.enableButton();
      } else {
        // this.nextButtonTarget.setAttribute('disabled', 'disabled');
        this.disableButton();
      }
    }, 100);
  }

  validatePaymentDetails() {
    setTimeout(() => {
      const stripeElements = document.querySelectorAll('.card-element, .card-element-inner');

      const allElementsComplete = Array.from(stripeElements).every(element => {
        return element.classList.contains('StripeElement--complete');
      });

      const cardholderName = document.getElementById('cardholder-name').value.trim();
      const isCardholderNameNotEmpty = cardholderName !== '';

      if (allElementsComplete && isCardholderNameNotEmpty) {
        this.nextButtonTarget.disabled = false;
        this._dispatchEnableButtonEvent(false)
      } else {
        this.nextButtonTarget.disabled = true;
        this._dispatchEnableButtonEvent(true)
      }
    }, 100)
  }

  next() {
    switch (this.currentStep) {
      case 1:
        this.handleFirstStep();
        break;
      case 2:
        this.handleSecondStep();
        break;
      case 3:
        this.handleThirdStep();
        break;
      case 4:
        this.handleFourthStep();
        break;
      default:
        this.advanceStep();
    }
  }

  handleFirstStep() {
    document.querySelectorAll('.spinner-container').forEach(element => {
      element.style.display = 'block';
    });
    document.querySelectorAll('.cars-container').forEach(element => {
      element.style.display = 'none';
    });
    const tripSummaries = document.querySelectorAll('[data-trip-summary-info]');
    // Store the first trip block and clear the container
    const container = document.getElementById('car-trip-selection');
    const firstTripBlock = container.querySelector('[data-trip-index="1"]');
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }

    // Re-append the first trip block if it exists
    if (firstTripBlock) {
      container.appendChild(firstTripBlock);
    }

    tripSummaries.forEach((summary) => {
      const tripIndex = summary.getAttribute('data-trip-index');
      const pickupLocation = summary.querySelector(`[data-pickup-location-${tripIndex}]`).textContent.split(',')[0];;
      const dropoffLocation = summary.querySelector(`[data-dropoff-location-${tripIndex}]`).textContent.split(',')[0];;

      if (tripIndex > 1) {
        container.appendChild(this.addTripBlock(tripIndex, pickupLocation, dropoffLocation));
      }
    });
    const totalPriceElement = document.querySelector('span.total-price');
    if (totalPriceElement.style.display === 'block') {
      this.advanceStep(true);
    } else {
      this.advanceStep();
    }
    this.checkVehicleTypesAndProceed();
    this.validateCars();
  }

  checkVehicleTypesAndProceed() {
    this.checkVehicleAvailabilities()
      .then((results) => {
        document.querySelectorAll('li.car-selection-button').forEach(element => {
          let category = element.dataset.category;
          let carTypeId = element.dataset.typeId;
          let tripIndex = element.dataset.tripIndex;
          let vehicle = results[tripIndex].find(result => result.vehicleType.id == carTypeId && result.price > 0);

          if (vehicle) {
            element.style.display = 'block';
          } else {
            element.style.display = 'none';
          }
        });

        const carList = document.querySelector(`.car-selection-wrapper[data-trip-index="1"] ul`);
        $(carList).show();

      })
      .catch((error) => {
        console.error('Error checking vehicle availabilities:', error);
      });
  }

  checkVehicleAvailabilities() {
    return new Promise((resolve, reject) => {
      let bookingData = $('[data-booking-form]').serialize();
      let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      fetch('/check_vehicle_availabilities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': csrfToken
        },
        body: bookingData,
      })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        resolve(data);
      })
      .catch(error => reject(error));
    });
  }

  handleSecondStep() {
    this.advanceStep();
    this.validateContactDetails()
  }

  handleThirdStep() {
    this.nextButtonTarget.disabled = true;
    this.showSpinner();
    // Create the customer
    this.handleCustomerCreation(this.passengerCheckboxTarget.checked)
      .then(response => response.json())
      .then(data => {
        this.hideSpinner();
        if (!data.success) {
          console.error('Customer creation failed:', data.error);
          return;
        } else {
          this.customerStripeIdTarget.value = data.customer_id
          const setCustomer = new CustomEvent('setNewCustomer', {
            detail: { customerId: data.customer_id },
            bubbles: true,
          });
          document.dispatchEvent(setCustomer);
          if (data.existing_customer) {
            this.showSavedCardDetails(data.card_number, data.card_type);
          } else {
            this.showNewPaymentForm();
          }
          this.advanceStep(data.existing_customer);
        }
      })
      .catch(error => {
        this.hideSpinner();
        return;
      });
  }

  handleFourthStep() {
    const submitBookingForm = new CustomEvent('submitBookingForm', {
      bubbles: true,
    });
    document.dispatchEvent(submitBookingForm);
  }

  advanceStep(existingCustomer = false) {
    if (this.currentStep < this.stepTargets.length) {
      this.currentStep++;
      this.updateNextButton(existingCustomer);
      this.updateStepLabel(this.currentStep);
      this.updateStepProgressbar(this.currentStep);
      this.showCurrentStep();
    }
  }

  showSavedCardDetails(cardNumber, cardType) {
    this.paymentSectionTarget.setAttribute('data-setup-intent', 'false');
    this.savedCardDetailsTarget.style.display = "block";
    this.newPaymentDetailsTarget.style.display = "none";
    this.cardNumberTarget.textContent = cardNumber;
    document.getElementById('card_number_last_four').value = cardNumber;
    document.getElementById('card_type').value = cardType;
    this.renewCardTarget.checked = false;
    this.renewCardTarget.value = 0;
  }

  showNewPaymentForm() {
    this.paymentSectionTarget.setAttribute('data-setup-intent', 'true');
    this.savedCardDetailsTarget.style.display = "none";
    this.newPaymentDetailsTarget.style.display = "block";
    this.cardNumberTarget.textContent = '';
    document.getElementById('card_number_last_four').value = '';
  }


  updateStepLabel(currentStep) {
    this.steplabelTargets.forEach((el, index) => {
      var step = currentStep + 1
      if (index == currentStep - 1) {
        $(el).find('.step-trigger').addClass('highlighted');
        $(el).prev().addClass('highlighted');
      } else if (index > currentStep) {
        $(el).find('.step-trigger').removeClass('highlighted');
        $(el).prev().removeClass('highlighted');
      }
    });
  }

  updateStepProgressbar(currentStep) {
    const progress = (currentStep / this.stepTargets.length) * 100;
    this.element.querySelector('.progress-bar').style.width = `${progress}%`;
  }

  addTripBlock(tripIndex, pickupLocation, dropoffLocation) {
    if (tripIndex > 1) {

      const container = document.createElement("div")
      container.className = "car-selection-wrapper"
      container.classList.add('appended-trip-block');
      container.setAttribute("data-trip-index", tripIndex)

      const colDiv = document.createElement("div");
      colDiv.className = "col-12";

      const carSelectionDiv = document.createElement("div");
      carSelectionDiv.className = "car-selection";

      const tripDiv = document.createElement("div");
      tripDiv.className = "row trip-car-selection-button trip";
      tripDiv.style.height = "fit-content";
      tripDiv.setAttribute("data-action", "click->car-selection#reselect");

      const h3 = document.createElement("h3");
      h3.className = "mt-2 col-10 col-md-11";
      h3.textContent = `${pickupLocation} to ${dropoffLocation}`;
      h3.setAttribute("data-target", "booking-summary.trip");
      h3.setAttribute("data-trip-index", tripIndex);

      tripDiv.appendChild(h3);

      const editSpan = document.createElement("span");
      editSpan.className = "col-2 col-md-1 pt-2";
      editSpan.setAttribute("data-trip-car-selection-toggle", "" );
      editSpan.setAttribute("data-trip-index", tripIndex);
      editSpan.textContent = 'Edit';

      tripDiv.appendChild(editSpan);

      carSelectionDiv.appendChild(tripDiv);
      colDiv.appendChild(carSelectionDiv);
      container.appendChild(colDiv);

      let temp = document.querySelector("template").innerHTML;
      let newHtml = temp.replace(/\[TRIP_INDEX\]/g, tripIndex);
      container.insertAdjacentHTML('beforeend', newHtml);

      return container;
    }
  }

  previous(e) {
    e.preventDefault();
    if (this.currentStep > 1) {
      this.currentStep--;
      this.updateNextButton();
      this.showCurrentStep();
      this.updateButtonOnPrevious();
      this.validateFields();
      if (this.currentStep == 1 ) {
        this.hideBackButton();
      }
    }
  }

  previousStep(e) {
    e.preventDefault()
    const buttonStep = parseInt(e.currentTarget.dataset.step, 10);

    // Check if the step is larger than currentStep
    if (buttonStep >= this.currentStep) {
      return false;
    }
    this.currentStep = buttonStep
    this.updateStepLabel(this.currentStep - 1)
    this.showCurrentStep();
    this.updateButtonOnPrevious();
    this.validateFields();
  }

  hideBackButton() {
    var backButton = document.querySelector('button[data-target="sticky-bar.back"]');
    if (backButton) {
      backButton.classList.add('hidden');
    }
  }

  updateNextButton(existingCustomer = false) {
    switch (this.currentStep) {
      case 1:
        this.setNextButtonText("VEHICLE SELECTION", '1');
        break;
      case 2:
        this.setNextButtonText("CONTACT DETAILS", '2');
        break;
      case 3:
        this.setNextButtonText("REVIEW & PAY", '3');
        this.termsTarget.style.display = 'block';
        break;
      case 4:
        this.setNextButtonText("CONFIRM BOOKING", '4');
        this._dispatchUpdateButtonEvent(false, "CONFIRM BOOKING");
        this.termsTarget.style.display = 'block';
        break;
      default:
        console.error("Invalid step:", this.currentStep);
    }

    this.updateButtonState(existingCustomer);
  }

  setNextButtonText(text, step) {
    this.nextButtonTarget.textContent = text;
    this.nextButtonTarget.dataset.step = step;
  }


  updateButtonState(existingCustomer = false) {
    if (existingCustomer) {
      this.nextButtonTarget.disabled = false;
    } else {
      this.nextButtonTarget.disabled = true;
    }
    this._dispatchEnableButtonEvent(this.nextButtonTarget.disabled);
  }

  updateButtonOnPrevious() {
    this._dispatchClearDetailsEvent();
    if (this.currentStep === 1) {
      this.nextButtonTarget.textContent = "VEHICLE SELECTION";
      this.nextButtonTarget.dataset.step = '1';
      this.nextButtonTarget.disabled = false;
      this._dispatchUpdateButtonEvent(this.nextButtonTarget.disabled, "VEHICLE SELECTION");
    } else if (this.currentStep === 2) {
      this.nextButtonTarget.textContent = "CONTACT DETAILS";
      this.nextButtonTarget.dataset.step = '2';
      this.nextButtonTarget.disabled = false;
      this._dispatchUpdateButtonEvent(this.nextButtonTarget.disabled, "CONTACT DETAILS");
    } else if (this.currentStep === 3) {
      this.nextButtonTarget.textContent = "REVIEW & PAY";
      this.nextButtonTarget.dataset.step = '3';
      this.nextButtonTarget.disabled = false;
      this._dispatchUpdateButtonEvent(this.nextButtonTarget.disabled, "REVIEW & PAY");
    }
  }

  showCurrentStep() {

    this.stepTargets.forEach((el, index) => {
      el.hidden = (index + 1) !== this.currentStep;
    });
  }

  showSpinner() {
    this.passengerSectionTarget.style.display = 'none';
    this.paymentSpinnerTarget.style.display = 'block';
  }

  hideSpinner() {
    this.passengerSectionTarget.style.display = 'block';
    this.paymentSpinnerTarget.style.display = 'none';
  }

  handleCustomerCreation(passengerCheckbox) {
    const emailTarget = passengerCheckbox ? this.contactEmailAddressTarget : this.emailAddressTarget;
    const phoneNumberTarget = passengerCheckbox ? this.contactPhoneNumberTarget : this.phoneNumberTarget;
    const firstNameTarget = passengerCheckbox ? this.contactFirstNameTarget : this.firstNameTarget;
    const lastNameTarget = passengerCheckbox ? this.contactLastNameTarget : this.lastNameTarget;

    return fetch('/create_stripe_customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ payment: {
          email: emailTarget.value,
          phone_number: phoneNumberTarget.value,
          first_name: firstNameTarget.value,
          last_name: lastNameTarget.value
        }
      })
    });
  }

  toggleNewPaymentDetails(event) {
    if (event.target.checked) {
      event.target.value = 1
      this.paymentSectionTarget.setAttribute('data-setup-intent', 'true');
      this.newPaymentDetailsTarget.style.display = 'block';
      this.nextButtonTarget.disabled = true;
      this._dispatchEnableButtonEvent(true);
    } else {
      event.target.value = 0
      this.paymentSectionTarget.setAttribute('data-setup-intent', 'false');
      this.newPaymentDetailsTarget.style.display = 'none';
      this.nextButtonTarget.disabled = false;
      this._dispatchEnableButtonEvent(false);
    }
  }

  toggleContactDetails(event) {
    // this.validateContactDetails();
    setTimeout(() => {
      let allFieldsFilled = true;
      let emailIsValid = true;
      let requiredFields = Array.from(document.querySelectorAll('.required-contact-field'));

      if (this.passengerCheckboxTarget.checked) {
        const passengerFields = Array.from(document.querySelectorAll('.required-passenger-field'));
        requiredFields = requiredFields.concat(passengerFields);
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      requiredFields.forEach(field => {
        if (!field.value.trim()) {
          allFieldsFilled = false;
        }

        if (field.type === 'email' && !emailRegex.test(field.value.trim())) {
          emailIsValid = false;
        }
      });

      const divsWithErrors = document.querySelectorAll('.error');
      const containsError = divsWithErrors.length > 0;

      if (allFieldsFilled && emailIsValid && !containsError) {
        this.enableButton();
      } else {
        // this.nextButtonTarget.setAttribute('disabled', 'disabled');
        this.disableButton();
      }
    }, 100);

  }

  _dispatchClearDetailsEvent() {
    const clearDetailsEvent = new CustomEvent('clearDetails', {
      bubbles: true,
    });
    document.dispatchEvent(clearDetailsEvent);
  }

  _dispatchEnableButtonEvent(isEnabled) {
    const enableButtonEvent = new CustomEvent('enableButton', {
      detail: { disabled: isEnabled },
      bubbles: true,
    });
    document.dispatchEvent(enableButtonEvent);
  }

  _dispatchUpdateButtonEvent(isEnabled, textContent) {
    const updateButtonEvent = new CustomEvent('updateButton', {
      detail: { disabled: isEnabled, text:  textContent},
      bubbles: true,
    });
    document.dispatchEvent(updateButtonEvent);
  }

}
