import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["priceBreakdown", "total", "priceRange", "pickupLocation", "dropoffLocation",
                    "pickupAt", "carType", "emissionSavingsInfo", "requiredCarCount", 'actionContainer', 'stopLocation']

  initialize () {
    this.setupFormValidator()
  }

  handleDateChange(e) {
    const tripIndex = e.target.dataset.tripIndex
    const tripTimePicker = $(`.fp_timepicker.flatpickr-input[data-trip-index="${tripIndex}"]`)

    const hourPicker = $(`.fp_timepicker.flatpickr-input[data-trip-index="1"]`).parent().find('.flatpickr-hour')
    const tripPickupAtElement = $(`[data-trip-index="${tripIndex}"][data-tag="pickup-date-time-${tripIndex}"]`);
    $(tripPickupAtElement).valid();
  }

  connect() {
    // Listen for the custom event
    $('.geocode-pick-up ').attr('autocomplete','off');
    $('.geocode-drop-off ').attr('autocomplete','off');


    document.addEventListener('return-trip-added', function(e) {
      this.initialize();
    }.bind(this));

    document.addEventListener('DateValidatorChange', function(e) {
      this.handleDateChange(e);
    }.bind(this));

    document.addEventListener('carReset', this.resetCarCount.bind(this));
    document.addEventListener('quoteReset', this.resetQuoteDetails.bind(this))
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    document.removeEventListener('validFlightDetected', this.boundListener);
    document.removeEventListener('return-trip-added', this.boundListener);
    document.removeEventListener('carReset', this.resetCarCount.bind(this));
    document.removeEventListener('quoteReset', this.resetQuoteDetails.bind(this))
  }

  resetCarCount() {
    this.requiredCarCountTargets.forEach((el, index) => {
      el.textContent = ''
      el.closest('.col-md-12').classList.add('hidden')
      el.closest('.col-md-12').style.display = '';
    });

    this.priceBreakdownTargets.forEach((el, index) => {
      el.textContent = ''
    });
  }

  detailedQuote() {
    let validator = $('[data-booking-form]').validate()
    let currentTarget = event.currentTarget
    let context = this

    // When editing a trip in portal, it does not require real time fare calcs.
    if (validator == undefined) {
      return false
    }

    //Need the time out as JS is executing too fast and causing issues with false positive form validations
    setTimeout(function(){
      if (validator.checkForm()) {
        if($('[data-booking-form]').hasClass('edit_booking') || $('[data-booking-form]').hasClass('edit_trip')){
          return true
        }

        context.priceBreakdownTarget.textContent = ''

        if(context.checkCarTypeSuitablility(context)) {
          context.showSpinner()
          context.requestQuote()
          $('[data-generate-quote]').remove()
          if (context.hasActionContainerTarget) {
            context.actionContainerTarget.innerHTML = "<button type='button' id='btn-submit-booking-form' class='button button-full button-fill-violet' data-confirm-booking data-cy-submit-booking-form data-action='booking#confirm' }>CONFIRM BOOKING</button>"
          }
        }
      }
      else{
        if(currentTarget.id == "btn-generate_quote") {
          validator.showErrors()
        }

        // Reset booking summary fare details
        context.priceBreakdownTargets.forEach((el, i) => {
          $(el).html('')
        })

        if (context.hasTotalTarget) {
          context.totalTarget.textContent = '$0.00'
        }

        $('[data-confirm-booking]').remove()
        context.actionContainerTarget.innerHTML = "<button type='button' id='btn-generate_quote' class='button button-full button-fill-violet' data-generate-quote data-action='quote#detailedQuote' } >GENERATE QUOTE</button>"

      }
    }, 100)
  }

  quickQuote() {
    // Exit if dropoff is not filled
    let dropoffField = $('[id="booking_trips_attributes_0_dropoff_lng"]');
    if (!dropoffField.val()) {
      return false;
    }

    if ($('[data-booking-form]').valid()) {
      let bookingData = $('[data-booking-form]').serialize();
      this.priceRangeTarget.textContent = ''
      this.showSpinner()
      $.ajax({
        url: "/fare_quote",
        type: 'post',
        data: bookingData,
        beforeSend: () => {
          $.rails.CSRFProtection;
          $('[data-generate-quote]').html('GENERATING QUOTE...').attr('disabled', 'disabled');
        },
        success: (data) => {
          this.priceRangeTarget.textContent = '$' + data["quotes"][1].price_start
          document.querySelector('.fare-info').classList.remove('hidden');
           $("#quick_quote_btn").hide();
           this.hideSpinner();
           $('#quick_quote__form-button').show();
        }
      });
    }
  }

  private

  setupFormValidator() {
    const controller = this;
    this.buildCustomFormValidationRules()

    $('[data-booking-form]').validate({
      ignore: function (index, el) {
        var $el = $(el)
        var tripDetailsElement = $el.closest('[data-trip-details]')

        if ($el.hasClass('flight-number') && $(tripDetailsElement).find('[data-airport]').length) {
          return false
        }

         // Default behavior
         return $el.is(':hidden')
      },
      focusInvalid: false,
      onfocusout(element) {
        if ($(element).hasClass('geocode')) {
          return setTimeout((function() {
            $(element).valid()
            // trigger validation after the delay
          }), 500);
        } else {
          return $(element).valid()
        }
      },
      onkeyup: false,
      rules: {
        "booking[first_name]": {
          required: true
        },
        "booking[last_name]": {
          required: true
        },
        "booking[phone]": {
          required: true,
          validatePhoneNumber: true
        },
        "booking[email]": {
          required: function(element) {
            return ($('#booking_title').length == 0) && ($('#booking_contact:checked').length == 0);
          },
          email: true
        },
        "booking[alternative_first_name]": {
          required: "#booking_contact:checked",
        },
        "booking[alternative_last_name]": {
          required: "#booking_contact:checked",
        },
        "booking[alternative_phone]": {
          required: "#booking_contact:checked",
          validatePhoneNumber: true
        },
        "booking[alternative_email]": {
          email: true
        },
      },
      messages: {
        "booking[phone]": {
          required: "Please enter the passenger's mobile number.",
          validatePhoneNumber: "Please enter a valid phone number."
        },
        "booking[first_name]": {
            required: "Please enter the passenger's first name."
        },
        "booking[last_name]": {
            required: "Please enter the passenger's last name."
        },
        "booking[email]": {
          required: "Please enter the passenger's email."
        },
        "booking[alternative_first_name]": {
            required: "Please enter the contact's first name."
        },
        "booking[alternative_last_name]": {
            required: "Please enter the contact's last name."
        },
        "booking[alternative_phone]": {
          required: "Please enter the contact's mobile number.",
          validatePhoneNumber: "Please enter a valid phone number."
        }
      },
      errorPlacement($error, $element) {
        let shouldAppendError = true;
        let anchorElement = $element.parent()

        if ($element.hasClass("date")) {
          anchorElement = $element.closest('.flatpickr-calendar.hasTime')
        }

        if ($element.hasClass('flight-number')) {
          shouldAppendError = false;
          $element.parent().after($("<div></div>").append($error))
        }

        if (anchorElement.hasClass("intl-tel-input")) {
          anchorElement = anchorElement.parent()
        }

        var elementText = $($error).text();

        if (elementText === "Please enter a future time.") {
          anchorElement = $element.closest('.flatpickr-calendar.hasTime').parent()
        }

        if (anchorElement.hasClass("time-picker")) {
          var errorLabels = anchorElement.children().find('label.error');

          errorLabels.each(function() {
            if ($(this).text() === "Please enter hour and minute.") {
              if (elementText === "Please enter a future time.") {
                elementText.remove();
              }
            }

            if ($(this).text() === "Please enter a future time." && elementText === "Please enter hour and minute.") {
              shouldAppendError = false;
              return false;
            }

            if ($(this).text() === elementText) {
              $(this).remove();
            }
          });
        }

        if (shouldAppendError) {
          if ($element.closest('form')[0].className  == "form-inline") {
            $element.attr('data-toggle', 'popover')
            $element.attr('data-placement', 'bottom')
            $element.attr('data-content',$error.html())

            let popElement = $($element.popover('show').data('bs.popover').tip)
            popElement.addClass('error-popover')

            $element.on('click', function(){
              $element.popover('hide')
            })

          }
          else {
            $("<div></div>").append($error).appendTo(anchorElement)
          }
        }

      },
      success($error, element) {
        $(element).popover('hide')
        $(element).removeAttr('data-toggle')
        $(element).removeAttr('data-placement')
        $(element).removeAttr('data-content')

        $error.parent().remove()
      }
    })
  }

  checkAirport() {
    if(this.pickupLocationTarget.value == '') {
      $("#airport-message").html('')
    }
  }

  buildCustomFormValidationRules() {

    $.validator.addMethod("validatePhoneNumber", function(value, element){
      let err = undefined
      try {
        return $(element).intlTelInput('isValidNumber')
      } catch (_error) {
        err = _error
        return false
      }
    })

    $.validator.addMethod("locationOutOfRange", function(value, element){
      const tripIndex = element.dataset.tripIndex;
      let termini = document.querySelectorAll(`.geocode:not(.geocode-stop)[data-trip-index="${tripIndex}"]`);

      termini = Array.from(termini)
      let terminiAllHaveValues = termini.every(item => item.value !== '');

      if (!terminiAllHaveValues) {
        return true;
      }

      try {
        //  At least one service region is valid
        return termini.some((el) => {  return !placeOutOfRange($(el)) })
      } catch (_error) {
        let err = _error
        return false
      }

    }, "This trip is outside of our current service area.")

    $.validator.addMethod("placesAreNotEqual", function(value, element){
      try {
        const tripDataCont = $(element).closest('[data-trip-details]')
        const place = tripDataCont.find('.geocode-pick-up').val();
        const returnPlace = tripDataCont.find('.geocode-drop-off').val();

        if ((place === '') || (place == null) || (returnPlace == null) ||(returnPlace === '')) {
          return true;
        }

        return this.optional(element) || (place !== returnPlace)
      } catch (_error) {
        let err = _error
        return false
      }
    }, "Your pick up and drop off locations cannot be the same.")

    // //this doesn't work properly
    $.validator.addMethod("locationsTooClose", function(value, element){
      try {
        let result;
        const tripDataCont = $(element).closest('[data-trip-details]')
        const pickupLoc = tripDataCont.find('[data-type="pick_up_location"]')
        const dropoffLoc = tripDataCont.find('[data-type="drop_off_location"]')

        if ((pickupLoc === '') || (pickupLoc == null) || (dropoffLoc == null) ||(dropoffLoc === '')) {
          return true;
        }

        if (pickupLoc.data("lat") && dropoffLoc.data("lat")) {
          const pickup_lat = pickupLoc.data("lat")
          const pickup_lng = pickupLoc.data("lng")
          const dropoff_lat = dropoffLoc.data("lat")
          const dropoff_lng = dropoffLoc.data("lng")
          if ((pickup_lat === '') || (dropoff_lat === '')) {
            return true
          }

          result = placesTooClose(pickup_lat, pickup_lng, dropoff_lat, dropoff_lng)
        }

        return this.optional(element) || (result !== true)

      } catch (_error) {
        let err = _error
        return false
      }
    }, "Your pick up and drop off locations are too close.")

    $.validator.addMethod("hasPickupTime", (function(value, element) {
      return (value != "");
    }), "Please enter hour and minute.")


    $.validator.addMethod("futurePickupTime", (function(value, element) {
      return (moment($(element).closest('[data-trip-details]').find('.date')[0].value) > moment());
    }), "Please enter a future time.")

    $.validator.addMethod("pickupDateNotEmpty", (function(value, element) {
      return value.length > 0
    }), "Please enter your pick up date.")

    $.validator.addClassRules('flatpickr-hour', { 'hasPickupTime': true, 'futurePickupTime': true })
    $.validator.addClassRules('flatpickr-minute', { 'hasPickupTime': true, 'futurePickupTime': true })
    $.validator.addClassRules('flatpickr-am-pm', { 'futurePickupTime': true, 'futurePickupTime': true })

    $.validator.addMethod('validchildSeat', (function(value, element) {
      let validSelection = ($(element).val() != null) && ($(element).val() != '')
      return validSelection
    }), 'Please select an age group.')

    $.validator.addClassRules('childseat_selector__seat-selector',{
      'required': {
        depends(el) {
          return $(el).closest('[data-trip-details]').find('.childseat_selector__seat-label').length
        }
      },
        'validchildSeat': true
      }
    )

    $.validator.addMethod('validFlight', (function(value, element) {
      let validFlightDetails = ($(element).val() != null) && ($(element).val() != '') && $(element).data('valid-flight') == true

      return validFlightDetails
    }), '')

    $.validator.addClassRules('flight-number',{
      'required': {
        depends(el) {
          return $(el).closest('[data-trip-details]').find('[data-airport]').length
        }
      }
    })



    $.validator.addClassRules('date_selector', {  'pickupDateNotEmpty': true, 'hasPickupTime': true})

    $.validator.addClassRules('geocode', {"placesAreNotEqual": true, "locationsTooClose": true, "suburbPlace": true})

    $.validator.addMethod("pickupRequired", (function(value, element) {
      let locationContainer = $(element).closest('[data-location-container]')
      let locationDetails = $(locationContainer).find('[data-pickup-location-details]')
      let latitude = $(locationDetails).find('[data-latitude]').val()

      return value != "" && ( latitude != "" && latitude != null )
    }), "Please enter your pick up location.")

    $.validator.addClassRules('geocode-pick-up', {"pickupRequired": true, "locationOutOfRange": true })

    // stop geocode
    $.validator.addMethod("stopPickupRequired", (function(value, element) {
      let locationContainer = $(element).closest('[data-location-container]')
      let locationDetails = $(locationContainer).find('[data-stop-location-details]')
      let latitude = $(locationDetails).find('[data-latitude]').val()

      return value != "" && ( latitude != "" && latitude != null )
    }), "Please enter your stop location.")

    $.validator.addClassRules('geocode-stop', {"stopPickupRequired": true, "locationOutOfRange": true})

    $.validator.addMethod("dropoffRequired", (function(value, element) {
      let locationContainer = $(element).closest('[data-location-container]')
      let locationDetails = $(locationContainer).find('[data-dropoff-location-details]')
      let latitude = $(locationDetails).children('[data-latitude]').val()

      return value != "" && ( latitude != "" && latitude != null )
    }), "Please enter your drop off location.")

    $.validator.addMethod("suburbPlace", function(value, element){
      if($(element).data("suburb") == "true") {
        return false;
      }else {
        return true;
      }
    }, "Please enter a specific address.")

    $.validator.addClassRules('geocode-drop-off', {"dropoffRequired": true, "locationOutOfRange": true})

    $.validator.addMethod("returnPickupRequired", $.validator.methods.required, "Please enter a return pick up location.")
    $.validator.addClassRules('return-geocode-pick-up', {"returnPickupRequired": true, "locationOutOfRange": true})

    $.validator.addMethod("returnDropoffRequired", $.validator.methods.required, "Please enter a return drop off location.")
    $.validator.addClassRules('return-geocode-drop-off', {"returnDropoffRequired": true, "locationOutOfRange": true})

    $.validator.addMethod("chargeCodeRequired", (function(value, element) {
      let tripDetailsElement = $(element).closest('[data-trip-details]')
      let chargeCodeElement = $(tripDetailsElement).find('[data-charge-code]')
      let mandatoryFlag = chargeCodeElement.data('mandatory')

      return !mandatoryFlag || (value != "" && mandatoryFlag)
    }),
    function(params, element) {
      let tripDetailsElement = $(element).closest('[data-trip-details]')
      let chargeCodeElement = $(tripDetailsElement).find('[data-charge-code]')
      return 'Please enter a ' + $(chargeCodeElement).data('chargeCode').toLowerCase() + '.'
    })
    $.validator.addClassRules('charge_code', {"chargeCodeRequired": true})

  }

  checkCarTypeSuitablility(context) {
    const maxServiceRegionKM = 180 // This is half the range of a Model S

    let carTypeID = $('.car-selection-button.selected').data('type-id')
    let suitableCarType = true

    $('[data-pickup-location-details]:visible').each( (i, el) => {
      let lat = $(el).find('[data-latitude]').val()
      let lng = $(el).find('[data-longitude]').val()
      let googlePlace = new google.maps.LatLng(lat, lng)

      let serviceRegion = $('[data-service-regions]').data('serviceRegions').find(function(sr) {
        let regionCenterLatLng = new google.maps.LatLng(sr.geo_center_lat, sr.geo_center_lng)

        return greatCircleDistanceKm(regionCenterLatLng, googlePlace) < maxServiceRegionKM
      })

      $.ajax({
        type: 'GET',
        url: '/service_regions/' + serviceRegion.id + '/car_types/' + carTypeID,
        async: false,
        beforeSend: $.rails.CSRFProtection,
        dataType: 'json',
        cache: false,
        success(data) {
          return true
        },
        error(request, textStatus, errorThrown) {
          context.showCarTypeNotificationBox(JSON.parse(request.responseText).error)
          suitableCarType = false
        }
      })
    })

    return suitableCarType
  }

  showCarTypeNotificationBox(errorMsg) {
    let notificationInstructionText ="<p>We can assist you with booking in the transfers you require - simply click “Contact Us” and we will get back to you shortly.</p>"

    let modalContent = '<div class="modal-dialog modal-dialog-centered"><div class="modal-content modal-lg"><div class="modal-body">' + errorMsg + notificationInstructionText + '</div><div class="modal-footer"><a class="primary-link" data-close-notification data-action="booking#confirmAcknowledge">Return to dashboard</a> <a class="button button-fill-violet button-sm" data-assisatnce-request data-action="booking#requestAssistance">Contact Us</a></div></div></div>'

    $('[data-booking-modal]').html(modalContent)
    $('[data-booking-modal]').modal()
  }

  showSpinner() {
    $('.fare-information-box').hide()
    $('.fine-print').hide()
    $('.fare-loading-message').show()
    $('.fare-loading-spinner').show()
  }

  hideSpinner() {
    $('.fare-loading-message').hide()
    $('.fare-loading-spinner').hide()
    $('.fine-print').show()
  }

  obtainQuote(data) {
    if($(".geocode-pick-up").valid() && $(".geocode-drop-off").valid() && $(".date_selector").valid() && $("[data-slick-time-picker-hour]").valid()) {
      this.displayQuoteRange(data);
    }
  }

  displayQuoteRange(quoteData) {
    this.priceRangeTarget.textContent = '$' + quoteData.quotes['1'].price_start

    $("#quick_quote_btn").hide();
    $('.fare-loading-spinner').hide()
    $('#quick_quote__form-button').show();
    // $('#quick_quote__form-button').prop('name', 'no_date_commit')
    $('#quick_quote__form-button').prop('disabled', false)
    $('.fare-information-box-new .fare-info').show()
    $('.fine-print').show()
    $('.fare-information-box-new .fare-info').addClass('d-flex')
    $('#quick_quote__form-button').show()
  }

  displayQuoteDetails(data, index) {
    let maxTripIndex = this.findMaxTripIndex();
    if(this.hasPriceRangeTarget){
      this.obtainQuote(data)
    }
    else {
      let finalTotal = 0.00
      let totalEmissionsSavings = 0.0
      this.requiredCarCountTargets.forEach((el, i) => {
        el.textContent = data.quotes[1].cars_required
        $(el).parent().show()
      })

      this.priceBreakdownTargets.forEach((el, i) => {
        let tripIndex =  el.getAttribute("data-trip-index")
        finalTotal = finalTotal + data.quotes[tripIndex].price
        totalEmissionsSavings = (totalEmissionsSavings + data.quotes[tripIndex].co2_savings)

        if (i <= index - 1) {
          $.get('/quote_price_breakdown', {
            headers: {
                "Content-Type": "application/json",
            },
            quote: JSON.stringify(data.quotes[tripIndex])
          })
          .then(html => {
            let invalid_quote = html.includes("Unable to generate quote");
            $('#btn-submit-booking-form').attr("disabled", invalid_quote);
            $('#btn-submit-booking-form').toggleClass('disabled', invalid_quote);
            $('#booking-error-panel').toggleClass('d-none', !invalid_quote);
            el.innerHTML = html;
            let parentRow = el.closest('.row');
            if (parentRow) {
              parentRow.style.display = '';
            }
          });
        }
      })

      if (this.hasTotalTarget && maxTripIndex == index) {
        this.totalTarget.textContent = this.formatMoney(finalTotal.toFixed(2))
        const panelDiv = this.totalTarget.closest('.panel')
        if (panelDiv) {
          panelDiv.style.display = 'block'
        }
        const finalTotalQuote = document.getElementById('final-total');
        finalTotalQuote.setAttribute('data-loading', 'false');
        const quoteFinalizedEvent = new CustomEvent('quoteFinalized', {
          detail: {
            totalAmount: finalTotal.toFixed(2)
          }
        });
        document.dispatchEvent(quoteFinalizedEvent);
      }

      if (this.hasEmissionSavingsInfoTarget) {
        $("#emission-savings-text")[0].innerHTML = "<span>" + totalEmissionsSavings.toFixed(2) + "kg offset. Learn more <a href='https://www.evoke.limo/sustainability' target='_blank' rel='noopener noreferrer'>here</a></span>";
        $(this.emissionSavingsInfoTarget).show();
      }

      $('[data-submit-booking-form]').val('Confirm Booking')
      $('[data-submit-booking-form]').data('disable-with', 'Confirm Booking')
      $('[data-submit-booking-form]').attr('data-disable-with', 'Confirm Booking')

      $('[data-submit-booking-form]').prop('disabled', false)

    }

  }

  formatMoney(amount) {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(parseFloat(amount)).toString()
  }

  resetQuoteDetails() {

    this.priceBreakdownTargets.forEach(el => {
        el.innerHTML = '';
        $(el).parent().hide();
    });

    if (this.hasTotalTarget) {
        this.totalTarget.textContent = '$0.00';
    }

    const panelDiv = this.totalTarget.closest('.panel')
    if (panelDiv) {
      panelDiv.style.display = 'none'
    }

    var totalPriceElement = document.querySelector('.total-price');
    if (totalPriceElement) {
      totalPriceElement.style.display = 'none';
    }

    if (this.hasEmissionSavingsInfoTarget) {
        $(this.emissionSavingsInfoTarget).hide();
    }

    $('#btn-submit-booking-form').attr("disabled", true);
    $('#btn-submit-booking-form').addClass('disabled');
    $('#booking-error-panel').addClass('d-none');

    $('[data-submit-booking-form]').prop('disabled', true);
    $('[data-submit-booking-form]').val('Submit Booking');
    $('[data-submit-booking-form]').data('disable-with', 'Submit Booking');
    $('[data-submit-booking-form]').attr('data-disable-with', 'Submit Booking');
  }

  requestQuote(event) {
    let tripIndex;
    const wrapper = event.currentTarget.closest('.car-selection-wrapper');
    const finalTotal = document.getElementById('final-total');
    const backButton = document.querySelector('[data-target="sticky-bar.back"]');

    backButton.setAttribute('disabled', 'disabled');

    if (wrapper && wrapper.dataset.tripIndex) {
      tripIndex = wrapper.dataset.tripIndex;
      // Specific logic for when tripIndex is provided
      this.priceBreakdownTargets.forEach((el, i) => {
        if (i == tripIndex - 1) {
          el.textContent = '';
          // Show the spinner and message for the corresponding trip index
          $('.location-spinner-box').eq(i).show();
          $('.fare-loading-message').eq(i).show();
          $('.fare-loading-spinner').show();
        }
      });
    }

    let bookingData = $('[data-booking-form]').serialize();

    if (this.hasTotalTarget) {
      this.totalTarget.closest('.panel').style.display = "none";
    }
    finalTotal.setAttribute('data-loading', 'true'); // Add data attribute when starting quote

    $.ajax({
      url: "/fare_quote",
      type: 'post',
      data: bookingData,
      beforeSend: () => {
        $.rails.CSRFProtection;
        $('[data-generate-quote]').html('GENERATING QUOTE...').attr('disabled', 'disabled');
      },
      success: (data) => {
        let totalAmount = 0;
        for (const key in data["quotes"]) {
          totalAmount += data["quotes"][key].price;
        }
        if (tripIndex !== undefined) {
          // Hide only the spinners and messages associated with a specific tripIndex
          $('.location-spinner-box').eq(tripIndex - 1).hide();
          $('.fare-loading-message').eq(tripIndex - 1).hide();
          this.displayQuoteDetails(data, tripIndex);
        } else {
          // General case when tripIndex is not provided
          $('.location-spinner-box').hide();
          $('.fare-loading-message').hide();
          this.displayQuoteDetails(data);
        }

        var totalPriceElement = document.querySelector('.total-price');
        if (totalPriceElement) {
          $(totalPriceElement).show();
        }

        backButton.removeAttribute('disabled');
        this.hideSpinner();
      }
    });
  }

  newRequestQuote(event) {
    let tripIndex;
    const wrapper = event.currentTarget.closest('.car-selection-wrapper');
    const finalTotal = document.getElementById('final-total');
    const backButton = document.querySelector('[data-target="sticky-bar.back"]');
    backButton.setAttribute('disabled', 'disabled');
    const amount = wrapper.querySelector('.selected').dataset.amount
    if (wrapper && wrapper.dataset.tripIndex) {
      tripIndex = wrapper.dataset.tripIndex;
      // Specific logic for when tripIndex is provided
      this.priceBreakdownTargets.forEach((el, i) => {
        if (i == tripIndex - 1) {
          el.textContent = '';
          $('.location-spinner-box').eq(i).show();
          $('.fare-loading-message').eq(i).show();
        }
      });
    }

    if (this.hasTotalTarget) {
      this.totalTarget.closest('.panel').style.display = "none";
    }
    finalTotal.setAttribute('data-loading', 'true'); // Add data attribute when starting quote
    let maxTripIndex = this.findMaxTripIndex();

    if (this.hasTotalTarget && maxTripIndex == tripIndex) {
        this.totalTarget.textContent = '$' + amount
        const panelDiv = this.totalTarget.closest('.panel')
        if (panelDiv) {
          panelDiv.style.display = 'block'
        }
        const finalTotalQuote = document.getElementById('final-total');
        finalTotalQuote.setAttribute('data-loading', 'false');

      }

  }


  findMaxTripIndex() {
    let maxIndex = -1;

    this.priceBreakdownTargets.forEach((el) => {
      let tripIndex = parseInt(el.getAttribute("data-trip-index"), 10);

      if (tripIndex > maxIndex) {
        maxIndex = tripIndex;
      }
    });

    return maxIndex;
  }

  loadVehicleTypes(event) {
    const elements = document.querySelectorAll('.geocode');

    // Check if all elements have a value
    const allHaveValues = Array.from(elements).every(element => {
      return element.value.trim() !== '';
    });

    // Find the dropdown element by its ID or class
    const dropdown = document.getElementById('car_type');

    // If all elements have a value, enable the dropdown
    if (allHaveValues) {

        let bookingData = $('[data-booking-form]').serialize();
        let csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

        fetch('/check_vehicle_availabilities', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRF-Token': csrfToken
          },
          body: bookingData,
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Network response was not ok');
          }
        })
        .then(data => {
          const dataObjKeys = Object.keys(data);

          if (dataObjKeys.length > 0) {
            dropdown.innerHTML = '';
          }

          dataObjKeys.forEach(function (key) {
            const availableCarTypes = data[key]

            availableCarTypes.forEach(function (carType) {
              const option = document.createElement('option');
              option.value = carType.vehicleType.id;
              option.text = carType.vehicleType.name;
              dropdown.appendChild(option);
            })
          })

          let event2 = new Event('change');
          dropdown.dispatchEvent(event2);
        })
        .catch(error => reject(error));



      dropdown.removeAttribute('disabled');
    } else {
      // Optionally, disable the dropdown if not all elements have a value
      dropdown.setAttribute('disabled', 'disabled');
    }
  }
}
