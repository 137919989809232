import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectButton"];

  connect() {
    document.addEventListener('carReset', this.resetCar.bind(this));
  }

  disconnect() {
    document.removeEventListener('carReset', this.resetCar.bind(this));
  }

  resetCar() {
    //Reset to index 1
    const cols = document.querySelectorAll(`.car-selection-wrapper[data-trip-index="1"] .col-12`);
    cols.forEach((col, index) => {
      if (index > 0) { // Skip the first col-12
        col.style.display = 'block'; // Hide the element
      }
    });
    let selectButton = this.element.querySelector(`[data-target="car-selection.selectButton"][data-trip-index="1"]`);
    if (selectButton) {
      selectButton.disabled = true;
    }
  }

  selected(e) {
    e.preventDefault();
    let carName = e.currentTarget.querySelector('.car-selection__model-name').textContent;
    let tripIndex = e.currentTarget.closest('.car-selection-wrapper').dataset.tripIndex
    const currentTripIndex = e.currentTarget.dataset.tripIndex
    const nextTripIndex = parseInt(currentTripIndex, 10) + 1;

    const carSelectionEvent = new CustomEvent('carSelected', {
      detail: { carSelected: true, carName: carName, tripIndex: tripIndex, nextTripIndex: nextTripIndex },
      bubbles: true,
    });
    this.element.dispatchEvent(carSelectionEvent);

    const nextWrapper = document.querySelector(`.car-selection-wrapper[data-trip-index="${nextTripIndex}"]`);
    if (nextWrapper) {
      const currentTripCarList = document.querySelector(`.car-selection-wrapper[data-trip-index="${currentTripIndex}"] ul`);
      $(currentTripCarList).hide();

      const nextWrapperEditButton = document.querySelector(`[data-trip-car-selection-toggle][data-trip-index="${nextTripIndex}"]`);
      $(nextWrapperEditButton).hide()


      const nextTripCarList = document.querySelector(`.car-selection-wrapper[data-trip-index="${nextTripIndex}"] ul`);
      $(nextTripCarList).show();

      const otherEditButtons = document.querySelectorAll(`[data-trip-car-selection-toggle]:not([data-trip-index="${nextTripIndex}"])`);

      otherEditButtons.forEach(otherEditButton => {
        $(otherEditButton).show();
      });
    }
  }

  reselect(event) {
    const currentTripIndex = event.currentTarget.closest('.car-selection-wrapper').dataset.tripIndex
    const editButton = document.querySelector(`[data-trip-car-selection-toggle][data-trip-index="${currentTripIndex}"]`);
    $(editButton).hide();

    const carList = document.querySelector(`.car-selection-wrapper[data-trip-index="${currentTripIndex}"] ul`);
    $(carList).show();

    const otherEditButtons = document.querySelectorAll(`[data-trip-car-selection-toggle]:not([data-trip-index="${currentTripIndex}"])`);

    otherEditButtons.forEach(otherEditButton => {
      $(otherEditButton).show();
    });

    const OtherCarLists = document.querySelectorAll(`.car-selection-wrapper:not([data-trip-index="${currentTripIndex}"]) ul`);
    OtherCarLists.forEach(otherCarList => {
      $(otherCarList).hide();
    });
  }
}
