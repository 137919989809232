import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    // Initialize the tooltip using Bootstrap's JavaScript API
    $(this.element.querySelectorAll('[data-toggle="tooltip"]')).tooltip();
  }

  show(event) {
    // Show the tooltip
    $(event.target).tooltip('show');
  }

  hide(event) {
    // Hide the tooltip
    $(event.target).tooltip('hide');
  }
}
