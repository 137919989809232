import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['cardElement', 'submit', 'lastFour'];
  isClearing = false;
  async connect() {

    if (typeof Stripe === "undefined") {
      return;
    }

    var stipeApiPublishedKey = $("meta[name='sapk']").attr("content");
    this.stripe = Stripe(stipeApiPublishedKey);
    this.elements = this.stripe.elements({
      fonts: [
          {
            cssSrc: "https://fonts.cdnfonts.com/css/satoshi",
          },
          {
            family: 'Satoshi',
            weight: 400
          },
      ]
    });
    var style = {
      base: {
        fontFamily: 'Satoshi',
        fontSize: '18px'
      }
    };

    this.cardNumber = this.elements.create('cardNumber', { style: style, placeholder: "Card number" });
    this.cardExpiry = this.elements.create('cardExpiry', { style: style, placeholder: "Expiration date (MM / YY)" });
    this.cardCvc = this.elements.create('cardCvc', { style: style, placeholder: "Security code" });

    // Mount the elements to the DOM
    this.cardNumber.mount('#card-number-element');
    this.cardExpiry.mount('#card-expiry-element');
    this.cardCvc.mount('#card-cvc-element');
    this.cardNumber.on('change', (event) => {
      this.updateLogoVisibility(event.brand)
      this.handleCardElementChange(event, 'card-number-error')
      var parentDiv = document.getElementById('card-number-container');

      if (event.complete) {
        parentDiv.classList.add('stripe-complete');
      } else {
        parentDiv.classList.remove('stripe-complete');
      }

      if (event.error) {
        parentDiv.classList.add('stripe-error');
      } else {
        parentDiv.classList.remove('stripe-error');
      }
    });

    this.cardExpiry.on('change', (event) => {
      this.handleCardElementChange(event, 'card-expiry-error');
    });
    this.cardCvc.on('change', (event) => {
      this.handleCardElementChange(event, 'card-cvc-error');
      var parentDiv = document.getElementById('card-cvc-container');

      if (event.complete) {
        parentDiv.classList.add('stripe-complete');
      } else {
        parentDiv.classList.remove('stripe-complete');
      }

      if (event.error) {
        parentDiv.classList.add('stripe-error');
      } else {
        parentDiv.classList.remove('stripe-error');
      }
    });
    document.addEventListener('setNewCustomer', this.setCustomer.bind(this));
    document.addEventListener('handleSetupIntent', this.handleSubmit.bind(this));
    document.addEventListener('clearDetails', this.clearDetails.bind(this));
  }

  disconnect() {
    document.removeEventListener('setNewCustomer', this.setCustomer.bind(this));
    document.removeEventListener('handleSetupIntent', this.handleSubmit.bind(this));
    document.removeEventListener('clearDetails', this.clearDetails.bind(this));
  }

  updateLogoVisibility(eventBrand) {

    const logoIDs = {
      visa: 'visa-logo',
      mastercard: 'mastercard-logo',
      diners: 'diners-logo',
      discover: 'discover-logo',
      jcb: 'jcb-logo',
      amex: 'amex-logo'
    };

    Object.entries(logoIDs).forEach(([brand, logoId]) => {
      const element = document.getElementById(logoId);
      if (element) {
        element.classList.toggle('hidden', brand !== eventBrand);
      }
    });
  }

  async handleSubmit() {
    const cardholderName = document.getElementById('cardholder-name').value;
    const clientSecret = await this.fetchSetupIntentClientSecret();
    const { setupIntent, error } = await this.stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: this.cardNumber,
        billing_details: {
          name: cardholderName
        },
      },
    });

    if (error) {
      console.error(error);
       $('#card-number-error').text(error.message);
       $('#card-number-container').addClass('stripe-error');

       $('#card-number-error').show();

       $('[data-pending-modal-header]').empty()
       $('[data-pending-modal-body]').empty()
       $('[data-pending-modal-footer]').empty()
       $("#booking-pending-modal").modal('toggle')

    } else {
      this.lastFourTarget.value = setupIntent.payment_method
      this.element.dispatchEvent(new CustomEvent('setupIntentComplete', {
        bubbles: true
      }));
      $('[data-booking-form]').trigger('submit.rails');
    }
  }

  async fetchSetupIntentClientSecret() {
    let customerId = this.cardElementTarget.getAttribute('data-customer-id');
    const response = await fetch('/create_setup_intent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
         'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        customer_id: customerId, // Pass the customer ID to your backend
      }),
    });
    const data = await response.json();
    return data.client_secret;
  }

  setCustomer() {
    let customerId = event.detail.customerId

    this.cardElementTarget.setAttribute('data-customer-id', customerId);
  }

  clearDetails() {
    this.isClearing = true;
    this.cardNumber.clear();
    this.cardExpiry.clear();
    this.cardCvc.clear();
    document.getElementById('cardholder-name').value = '';

    setTimeout(() => {
      this.isClearing = false;
    }, 100);
  }

  handleCardChange(event) {
    this.element.dispatchEvent(new CustomEvent('cardDetailsComplete', {
      detail: { cardEvent: event }, // Pass the original event or its relevant data in the 'detail' property
      bubbles: true
    }));
  }

  handleCardElementChange(event, errorElementId) {
    if (!this.isClearing) {
      const errorMessageContainer = document.getElementById(errorElementId);
      if (event.error) {
        errorMessageContainer.textContent = event.error.message;
        errorMessageContainer.style.display = 'block';
      } else {
        errorMessageContainer.textContent = '';
        errorMessageContainer.style.display = 'none';
      }
      this.element.dispatchEvent(new CustomEvent('handleCardDetails', {
        bubbles: true
      }));
    }
  }
}
