import { ApplicationController } from "./application_controller.js"

export default class extends ApplicationController {
  static targets = ["continue", "total", "totalWrapper", "buttonText", "back", "arrowButton", "forward"];

  connect() {
    document.addEventListener('enableButton', this.enableButton.bind(this));
    document.addEventListener('quoteFinalized', this.updateTotal.bind(this));
    document.addEventListener('updateButton', this.updateButton.bind(this));
  }

  disconnect() {
    document.removeEventListener('enableButton', this.enableButton.bind(this));
    document.removeEventListener('quoteFinalized', this.updateTotal.bind(this));
    document.removeEventListener('updateButton', this.updateButton.bind(this));
  }

  enableButton(e) {
    const isDisabled = e.detail.disabled;
    if (!isDisabled) {
      this.continueTarget.disabled = false
    } else {
      this.continueTarget.disabled = true
    }
  }

  continue(e) {
    const nextButton = document.querySelector('[data-target="stepper.nextButton"]');

    if (nextButton) {
      nextButton.click();
      this.buttonTextTarget.textContent = nextButton.textContent
    }
    if (this.backTarget.classList.contains('hidden')) {
        this.backTarget.classList.remove('hidden');
    }

  }

  back(e) {
    this._dispatchBackButtonEvent(e.currentTarget.dataset.step );
    if (this.arrowButtonTarget.classList.contains('hidden')) {
      this.arrowButtonTarget.classList.remove('hidden');
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  updateTotal(e) {
    this.totalWrapperTarget.style.display = "block"
    this.totalTarget.innerHTML =  this.formatMoney(e.detail.totalAmount) + ' <span class="currency">AUD</span>';
  }

  formatMoney(amount) {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return formatter.format(parseFloat(amount)).toString()
  }

  updateButton(e) {
    this.arrowButtonTarget.classList.add('hidden');
    const isDisabled = e.detail.disabled;
    const textContent = e.detail.text;
    if (!isDisabled) {
      this.continueTarget.disabled = false
      this.buttonTextTarget.textContent = textContent
    } else {
      this.continueTarget.disabled = true
      this.buttonTextTarget.textContent = textContent
    }
  }

  _dispatchBackButtonEvent(step) {
    const backButtonEvent = new CustomEvent('backButton', {
      detail: { step: step },
      bubbles: true,
    });
    document.dispatchEvent(backButtonEvent);
  }

}

