// Class used to extend our custom controllers. Put shared methods in this class.
import { Controller } from "stimulus"

export class ApplicationController extends Controller {

  private
    lookupServiceRegion(obj) {
      let serviceRegion = null

      const maxServiceRegionKM = 180 // This is half the renage of a Model S

      if (typeof obj === 'string') {
        serviceRegion = $('[data-service-regions]').data('serviceRegions').find(function(sr) {
          return sr.name == obj
        })
      }
      else if (typeof obj === 'object') {
        let locationDataFields = $(locationElement).siblings('.location-fields')

        let lat = $(locationDataFields).find('[data-latitude]').val()
        let lng = $(locationDataFields).find('[data-longitude]').val()
        let googlePlace = new google.maps.LatLng(lat, lng)

        serviceRegion = $('[data-service-regions]').data('serviceRegions').find(function(sr) {
          let regionCenterLatLng = new google.maps.LatLng(sr.geo_center_lat, sr.geo_center_lng)
          return greatCircleDistanceKm(regionCenterLatLng, googlePlace) < maxServiceRegionKM
        })
      }

      return serviceRegion
    }

    showAddRegionalAirportPickupTripButton(locationElement, flightData) {
      let tripDetailsContainer = $(locationElement).closest('[data-trip-details]')
      let tripIndex = $(locationElement).data('tripIndex') || '0'
      let totalTripCount = $('[data-trip-details]').length
      // If the current trip being edited is NOT the last trip in the booking, exit.
      if (tripIndex != totalTripCount){
        return true
      }

      let regionalTripButton = $('[data-regional-pickup]')

      $.ajax({
        url: "/api/v1/airports/supported",
        type: 'post',
        data: {
          iata_code: flightData.arrival_iata_code
        },
        beforeSend: $.rails.CSRFProtection,
        success: (data) => {
          let airportName = data.airport.name;

          if ((data.supported) && (airportName != 'Melbourne')){
            $(regionalTripButton).html('Add ' + data.airport.name + ' Trip')
            $(regionalTripButton).css('display', 'block')
            $(regionalTripButton).data('pickup-airport', data.airport)
            $(regionalTripButton).attr('data-pickup-airport', data.airport)
            $(regionalTripButton).data('arrival-flight', flightData)
            $(regionalTripButton).attr('data-arrival-flight', flightData)
          }
          else {
            $(regionalTripButton).css('display', 'none')
          }
        }
      })

    }

    flightLookup(flightNumberElement) {
      let flightDirection
      let tripDetailsContainer = $(flightNumberElement).closest('[data-trip-details]')

      let airportElement = $(tripDetailsContainer).find('[data-airport]')
      let flightData = ''

      if (airportElement.length) {
        if ($(airportElement).data('type') === 'pick_up_location') {
          flightDirection = 'arriving'
        } else {
          flightDirection = 'departing'
        }


        const tripIndex = $(tripDetailsContainer)[0].dataset.tripIndex
        const travelDate = $(`[data-tag="pickup-date-time-${tripIndex}"]`).val()
        $.ajax({
          type: 'POST',
          url: '/search/flights',
          async: false,
          data: {
            flightNumber: $(flightNumberElement).val().replace(/\s/g, "") ,
            date: travelDate,
            direction: flightDirection,
            iata_code: $(airportElement).data('airport').iata_code,
            airport_lat: $(tripDetailsContainer).find('[data-latitude]').val(),
            airport_lng: $(tripDetailsContainer).find('[data-longitude]').val()
          },
          beforeSend: $.rails.CSRFProtection,
          dataType: 'json',
          cache: false,
          success(data) {
            return flightData = data
          }
        })
      }

      return flightData
    }
}
