import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['stopLocation', 'pickupLocation', 'dropoffLocation', 'pickupAt', 'pickupTime', 'trip', 'vehicleType' ]

  connect() {
    let pickuptargets = ['pickupAt', 'pickupTime']
    let targetElement = null
    let context = this

    $('[data-summary-target]').each( (_, el) => {
      if($(el).hasClass('date')) {
        var tripIndex = $(el).data().tripIndex || '0'
        let momentObj = moment($(el).val())
        let formattedDate = momentObj.format('DD MMMM YYYY')
        let formattedTime =  momentObj.format('hh:mm A')

        $.each(pickuptargets , function(index, val) {
          targetElement = context.targets.findAll(val).find(x => $(x).data('tripIndex') == tripIndex)
          targetElement.textContent = (val == 'pickupAt') ? formattedDate : formattedTime
        })
      }
    })
    document.addEventListener('carSelected', this.updateVehicle.bind(this));
    document.addEventListener('updateTrip', this.updateTripDetails.bind(this));
  }

  disconnect() {
    document.removeEventListener('carSelected', this.updateVehicle.bind(this));
    document.removeEventListener('updateTrip', this.updateTripDetails.bind(this));
  }


  updateVehicle(event) {
    const carName = event.detail.carName;
    const tripIndex = event.detail.tripIndex;
    const nextTripIndex = event.detail.nextTripIndex;
    const vehicleTypeTargets = this.element.querySelectorAll(`[data-target="booking-summary.vehicleType"][data-trip-index="${tripIndex}"]`);

    vehicleTypeTargets.forEach(el => {
      const elTripIndex = parseInt(el.dataset.tripIndex, 10);
      if (elTripIndex == tripIndex) {
        el.textContent = carName; // Update the current vehicle type
        el.closest('.col-md-12').classList.remove('hidden'); // Unhide the element
      }
    });
  }

  update() {
    var tripIndex = event.target.dataset.tripIndex || '0'
    var summaryTarget = event.target.dataset.summaryTarget
    let targetElement = null
    let context = this
    if($('[data-booking-form]').hasClass('edit_booking') || $('[data-booking-form]').hasClass('edit_trip')){
      return true
    }

    if (summaryTarget != null) {
      if (summaryTarget == 'pickupAt') {
        let pickuptargets = ['pickupAt', 'pickupTime']

        let momentObj = moment(event.target.value)
        let formattedDate = momentObj.format('DD MMMM YYYY')
        let formattedTime =  momentObj.format('hh:mm A')

        $.each(pickuptargets , function(index, val) {
          targetElement = context.targets.findAll(val).find(x => $(x).data('tripIndex') == tripIndex)
          targetElement.textContent = (val == 'pickupAt') ? formattedDate : formattedTime
        })
      }
      else {
        let locationContainer = $(event.target).closest('[data-location-container]')
        targetElement = context.targets.findAll(summaryTarget).find(x => $(x).data('tripIndex') == tripIndex)
        let str = $(event.target).hasClass('fave-location') ? $(locationContainer).find('.geocode').val() : event.target.value
        if (/Location/i.test(summaryTarget)){
          str = str.split(',').slice(0, -1).join(',')
        }

        targetElement.textContent = str
      }
    }

    const finalTotal = document.getElementById('final-total')
    const isLoading = finalTotal.getAttribute('data-loading') === 'true';

    if((finalTotal.style.display == 'block' || isLoading) && event.currentTarget.className != "car-selection-button selected") {


      //reset pricing
      const carResetEvent = new CustomEvent('carReset', {
        detail: { carReset: true, eventClass: event.target.className },
        bubbles: true,
      });
      this.element.dispatchEvent(carResetEvent);

    };

    if(finalTotal.style.display == 'block' || isLoading) {

      const quoteResetEvent = new CustomEvent('quoteReset', {
        bubbles: true,
      });

      this.element.dispatchEvent(quoteResetEvent);
    }
  }


  updateTripDetails() {
    var tripIndex = event.target.dataset.tripIndex || '1'


    let pickupLocation = this.getLocationText(tripIndex, this.pickupLocationTargets);
    let dropoffLocation = this.getLocationText(tripIndex, this.dropoffLocationTargets);


    this.tripTargets.forEach((tripElement) => {
      if (tripElement.dataset.tripIndex === tripIndex) {
        tripElement.textContent = pickupLocation + ' to ' + dropoffLocation ; // Update textContent for the matched element
      }
    });

    const finalTotal = document.getElementById('final-total')
    const contactDetailsButton = document.getElementById('information-part-trigger');
    const isLoading = finalTotal.getAttribute('data-loading') === 'true';


    if((finalTotal.style.display == 'block' || isLoading) && (contactDetailsButton && !contactDetailsButton.classList.contains('highlighted'))) {
      //reset pricing
      const container = document.getElementById('car-trip-selection');
      const appendedBlocks = container.querySelectorAll('.appended-trip-block');

      appendedBlocks.forEach(block => {
        block.remove();
      });

      const carResetEvent = new CustomEvent('carReset', {
        detail: { carReset: true },
        bubbles: true,
      });
      this.element.dispatchEvent(carResetEvent);

      const quoteResetEvent = new CustomEvent('quoteReset', {
        bubbles: true,
      });

      this.element.dispatchEvent(quoteResetEvent);
    }


  }

  getLocationText(tripIndex, target) {
    let locationValue = null;

    target.forEach((element) => {
      if (element.dataset.tripIndex === tripIndex) {
        locationValue = element.textContent.split(',')[0];
      }
    });

    return locationValue;
  }

  createTripElement(tripIndex) {
    const container = document.createElement("div");
    container.classList.add("col-12");

    const carSelection = document.createElement("div");
    carSelection.classList.add("car-selection");

    const carSelectionButton = document.createElement("div");
    carSelectionButton.classList.add("car-selection-button", "trip");
    carSelectionButton.style.height = "fit-content";

    carSelectionButton.setAttribute("data-action", "click->car-selection#reselect");

    const h3 = document.createElement("h3");
    h3.dataset.target = `booking-summary.trip`;
    h3.dataset.tripIndex = tripIndex;

    carSelectionButton.appendChild(h3);
    carSelection.appendChild(carSelectionButton);
    container.appendChild(carSelection);

    // Append the container to the desired parent element in your DOM
    const parentElement = document.getElementById("car-trip-selection"); // Replace this with the actual parent element
    //parentElement.appendChild(container);
  }

  addTripSummary() {
    let newTripIndex = $('[data-trip-summary-info]').length + 1


    this.createTripElement(newTripIndex);


    $.get('/quote_trip_summary',{
      headers: {
          "Content-Type": "application/json",
      },
      trip_leg: newTripIndex
    })
    .then(html => {
      let panelBody = $('<div class="panel-body font-14 border-top mt-4">')
      panelBody.append(html)
      $(panelBody).insertAfter($('[data-trip-summary-info]:last').closest('.panel-body'))
      $('[data-trip-summary-heading="1"]').text('Trip 1')
      $('[data-trip-summary-heading="1"]').removeClass('hidden')
    })

    const quoteResetEvent = new CustomEvent('quoteReset', {
      bubbles: true,
    });

    this.element.dispatchEvent(quoteResetEvent);
  }

  private

  /**
   * Camelize a string, cutting the string by multiple separators like
   * hyphens, underscores and spaces.
   *
   * @param {text} string Text to camelize
   * @return string Camelized text
   */
  camelize(text) {
      return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function(match, p1, p2, offset) {
          if (p2) return p2.toUpperCase();
          return p1.toLowerCase();
      });
  }
}
