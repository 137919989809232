import { Controller } from "stimulus"

export default class extends Controller {

  addStop() {
    event.preventDefault();
    const container = document.getElementById('car-trip-selection');
    const appendedBlocks = container.querySelectorAll('.appended-trip-block');

    appendedBlocks.forEach(block => {
      block.remove(); // Remove each appended block from the DOM
    });

    const carResetEvent = new CustomEvent('carReset', {
      detail: { carReset: true },
      bubbles: true,
    });
    this.element.dispatchEvent(carResetEvent);

    const quoteResetEvent = new CustomEvent('quoteReset', {
      bubbles: true,
    });

    console.log('quote element dispatched');
    this.element.dispatchEvent(quoteResetEvent);
  }
}
