/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import $ from 'jquery'
import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import AirbrakeClient from 'airbrake-js'

require.context('../images', true)

const airbrake = new AirbrakeClient({
  projectId: 126676,
  projectKey: '67c59bf709c477db48509e758726eccc'
})

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

const defaultErrorHandler = application.handleError
const airbrakeErrorHandler = function (err) {
  defaultErrorHandler(err)
  airbrake.notify(err)
}

application.handleError = airbrakeErrorHandler

window.addEventListener("error", function (e) {
  airbrake.notify(e.error)
  return false
})

window.addEventListener('unhandledrejection', function (e) {
  airbrake.notify(e.error)
})

$(document).ready(function() {
  $("#instant_quote_form input").keydown(function(event){
    if(event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  });
});
